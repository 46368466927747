@import 'src/shared/styles/_variables.scss';

.input {
  & :global(.ant-input-affix-wrapper) {
    padding-right: 0;
  }

  & :global(.ant-input-group-addon) {
    background-color: $neutral0 !important;
  }
}

.accountTypeInputContainer {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.select {
  & :global(.ant-select-arrow) svg {
    fill: $neutral600;
  }

  &:global(.ant-select-disabled .ant-select-arrow) svg {
    display: none;
  }

  &:global(.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    cursor: auto;
    color: #3f505a;
  }
}

.avatar {
  background-color: $neutral100;

}

.editNameButton {
  display: flex;
  align-items: center;
}

.editNameButtonIcon {
  margin-bottom: 10px;
}

.rateSelect {
  width: 100px;
}

.info {
  position: relative;
  display: flex;
  align-items: center;
  padding: 24px;
  border: 1px solid #b3bfc6;
  border-radius: 2px;

  &__rates {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 36px;
  }

  &__rate {
    li:not(:last-child) {
      margin-right: 6px;
    }

    svg {
      font-size: 24px;
    }
  }

  &__user {
    width: 100%;
    padding-top: 4px;

    >* {
      margin-bottom: 0;
    }

    &__personal {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__level {
      display: flex;
      align-items: center;
      font-size: 18px;
      margin-bottom: 16px;

      a {
        font-size: 16px;
      }

      >div,
      >p {
        margin-bottom: 0;
      }
    }

    &__contact {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &__address {
      display: flex;
      align-items: center;
    }

    &_selectLevel {
      width: 180px;
      font-size: 18px;

      >div:first-child>span {
        display: flex;
        align-items: center;
        padding-right: 8px;
      }

      >div svg {
        font-size: 19px;
        margin-right: 10px;
      }
    }

    &_dropdown {
      width: 185px;
      font-size: 16px;

      >div>div:last-of-type>div:first-child>div>div>div {
        padding: 8px 10px;
      }

      >div>div:last-of-type>div:first-child>div>div>div {
        display: flex;
        align-items: center;
        height: 42px;
        font-size: 16px;

        >div {
          display: flex;
          align-items: center;
          flex: unset;
        }

        svg {
          font-size: 19px;
          margin-right: 10px;
        }
      }
    }

    &_dropdownSmallIcon {
      >div>div:last-of-type>div:first-child>div>div>div svg {
        font-size: 16px;
        margin-right: 10px;
      }
    }

    &_select {

      &:hover>div,
      &:active>div,
      &:focus>div {
        background: #ebf0f3 !important;
      }

      &:hover button,
      &:active button,
      &:focus button {
        display: inline-block;
      }

      >div {
        height: 36px !important;
        display: flex;
        align-items: center;

        svg {
          font-size: 16px;
          margin-right: 10px;
          color: #50626c;
        }
      }
    }

    &_selectEmail {
      width: 324px !important;

      .ant-select-selection-item {
        padding-right: 40px;
      }

      font-size: 16px;
      color: #3f505a;
    }

    &_selectPhone {
      width: 246px;
      height: 36px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.2px;
      color: #3f505a;
      margin-left: 10px;
    }

    &_name {
      font-weight: 600;
      font-size: 30px;
      color: #132b3a;
    }

    &_address {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 36px;
      font-size: 16px;
      color: #70838e;

      >div:first-child {
        padding: 0;
        margin-right: 5px;

        label {
          height: 36px;
          vertical-align: middle;
        }

        span {
          font-size: 16px;
          color: #70838e;
        }
      }

      >div:last-child {
        width: 476px;
      }

      svg {
        font-size: 18px;
        color: #50626c;
        margin-right: 10px;
      }

      button {
        position: absolute;
        top: 3px;
      }
    }

    &_block {
      position: relative;
      width: 580px;
      margin-top: 18px;

      &:hover input,
      &:active input,
      &:focus input {
        cursor: pointer;
        background: #ebf0f3 !important;
        border: 1px solid #40a9ff;
      }

      &:hover button,
      &:active button,
      &:focus button {
        display: inline;
      }
    }

    &_optionEmail {
      &::after {
        content: '(reserve)';
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.2px;
        color: #70838e;
        margin-left: 5px;
      }

      svg,
      button {
        display: none;
      }

      >div:first-child {
        flex: unset;
      }
    }

    &_optionPhone {
      &.work__number::after {
        content: '(work)';
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.2px;
        color: #70838e;
        margin-left: 5px;
      }

      svg,
      button {
        display: none;
      }

      >div:first-child {
        flex: unset;
      }
    }

    &_copyBtn {
      position: absolute;
      right: 15px;
      color: #132b3a;

      svg {
        font-size: 18px !important;
        margin: 0 !important;
      }

      &:hover svg,
      &:active svg,
      &:focus svg {
        color: #0091b5;
      }
    }

    &_input {
      height: 36px;
      padding-left: 5px;
      font-size: 16px;
      letter-spacing: 0.2px;
      color: #132b3a;
      border: 1px solid transparent;
    }
  }
}

.nameBlock {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  >button {
    opacity: 0;
    visibility: hidden;
    padding: 0;
    margin-left: 18px;
    color: #0091b5;

    svg {
      font-size: 18px;
      color: #0091b5;
    }
  }

  &:hover,
  &:focus {
    >button {
      opacity: 1;
      visibility: visible;
    }
  }
}

.name {
  font-weight: 600;
  font-size: 24px;
  color: #132b3a;
  margin: 0;
  padding-left: 0;
  margin-bottom: 10px;
}

.accName {
  box-sizing: border-box;
  width: calc(100% - 18px);
  margin: 0;
  padding-left: 0;
  font-weight: 600;
  font-size: 24px;
  color: #132b3a;

  &:hover {
    background-color: #f5f5f5;
  }

  &:focus {
    padding-left: 8px;
    background-color: unset;
    border: 1px solid #b3bfc6;
  }
}

.id {
  font-size: 16px;
  color: #3f505a;
  margin: 0;
}

.status {
  margin: 0;
  position: absolute;
  top: 12px;
  right: 12px;
  text-transform: capitalize;
  font-size: 12px;
}

.divider {
  background: #b3bfc6;
  margin: 0 16px;
}

.dropdown {
  &__space_phone {
    padding: 8px 10px;

    button {
      padding: 0;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.2px;
      color: #0091b5;
    }
  }

  &__space_email {
    width: 100%;
    padding: 8px 10px;

    >div:first-child {
      width: 90%;
    }
  }
}

.customer {
  &__linkBtn {
    padding: 0;
    font-size: 18px;
    color: #03a3bf;
  }

  &__date {
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #70838e;
  }
}

.form__btn {
  height: 36px;
  float: right;
}

.form__input {
  margin: 0;

  input {
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #3f505a;
  }
}

.form__input div>span {
  height: 36px;
}

.form__email {
  flex-grow: 1;
  min-width: 246px;
}

.space {
  margin-right: 8px;
}

.customerType {
  font-size: 18px;
  order: 2;
  width: 140px;

  & :global(.ant-select-selector) {
    padding: 0 !important;
  }
}

.email {
  display: flex;
  align-items: center;
  order: 3;
}

.textareaAccountName {
  width: 100%;
  margin-bottom: 6px;
}

.selectTypeFormItem {
  width: 150px;
  margin-bottom: 0;

  & :global(.ant-select-selector) {
    padding: 0 !important;
  }
}

.selectLevel {
  width: 180px;
}

.selectDropdownType {
  div>div div {
    font-size: 16px !important;
  }

  button.form__btn {
    height: auto;
    background: transparent;
    border: none;
    color: #0091b5;
  }
}

.selectAddress {
  flex-direction: row !important;
  align-items: center;

  >div {
    padding-bottom: 0 !important;
  }

  >div:last-child {
    width: 500px;
    margin-left: 10px;
  }

  div>span:last-child {
    font-size: 16px;
  }

  div:has(> span:first-child) {
    height: 36px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: unset;
  }

  button.form__btn {
    display: none;
  }
}

.labelAddress {
  font-size: 16px;
  color: #70838e;

  svg {
    font-size: 18px;
    color: #50626c;
    margin-right: 12px;
  }
}

.linkBtn {
  font-size: 16px;
  font-weight: 600;
  padding: 0;

  >span:last-child {
    margin: 0;
  }

  svg {
    margin-right: 16px;
    font-size: 20px;
  }
}

.addressName {
  margin-left: 10px;
  text-transform: lowercase;
  font-size: 16px;
  color: #70838e;
}

.addressItem>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addressNames {
  width: 85%;
  white-space: pre-wrap;
}

.userSelect {
  font-size: 16px;
}

.message {
  font-style: italic;
  color: #50626c;
}

@container article (max-width: 800px) {

  .avatar {
    display: none
  }

  .info {
    display: block;
    padding: 10px;
  }

  .info__user {
    padding: 0;
  }

  .accName {
    position: relative;
    padding: 0 50px 0 0;
    font-size: 20px;
  }
}

@container article (max-width: 500px) {

  .info__user__contact {
    flex-direction: column;
  }

  .form__input {
    width: 100%;
    max-width: 100%;
  }

}