.letter {
  opacity: 0.2;
  margin-right: 8px;
}
.textColor {
    animation: anim 1s alternate infinite;
  }

  @keyframes anim {
    0% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
    
  }